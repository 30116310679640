<template>
  <v-card outlined>
    <v-data-table></v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "ListaOsFinalizadas",

  props: {},

  components: {},

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},
};
</script>

<style>
</style>